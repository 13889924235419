import React, { useState, useEffect, useContext } from "react"
import SiteContext from "../context/SiteContext"

import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ScrollReveal from "scrollreveal"

import { Video } from "../components/slices"

const Container = styled.div`
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : "#eeede9"};

  padding-top: 2.5rem;

  @media (min-width: 576px) {
    padding-top: 0;
  }
`

const VideoWrapper = styled.div`
  height: fit-content;
  display: flex;
  align-items: center;
  visibility: hidden;
  flex-direction: column;
  justify-content: center;

  > div {
    width: calc(100% - 3rem) !important;

    margin: 0 !important;
    margin-top: 3rem !important;
  }

  .button {
    margin: 0.5rem auto !important;
  }

  @media (min-width: 576px) {
    height: 100vh;

    > div {
      width: 80% !important;
      max-width: 872px;

      margin: 0 !important;
      margin-top: 0rem !important;
    }

    .button {
      margin: 5rem auto 0 auto !important;
    }
  }
`

let colors = {
  backgroundColor: "#eeede9",
  color: "#272727",
}

export default props => {
  let context = useContext(SiteContext)
  let videoId = -1

  useEffect(() => {
    context.changeColor(colors.color)
  }, [])

  useEffect(() => {
    ScrollReveal({
      //   reset: true,
      delay: 1,
      distance: "30px",
    }).reveal(".video-wrapper")

    return () => {
      ScrollReveal().destroy()
    }
  }, [])

  return (
    <Layout backgroundColor={colors.backgroundColor} textColor={colors.color}>
      <SEO title="Video" />
      <Container backgroundColor={colors.backgroundColor}>
        {props.data.craft.entries.map(data => {
          videoId += 1
          return (
            <VideoWrapper className="video-wrapper">
              <Video
                data={data}
                id={videoId}
                backgroundColor={colors.backgroundColor}
                textColor={colors.color}
              />
            </VideoWrapper>
          )
        })}
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    craft {
      entries(section: ["video"]) {
        ... on Craft_video_video_Entry {
          title
          linkText
          linkField
          videoEmbedId
          videoPlatform
        }
      }
    }
  }
`
